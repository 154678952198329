.logo-nav{
    /* position: absolute; */
    height: 100px;
}
nav{
    top: 0;
    box-shadow:  0px 13px 24px 0px rgba(0, 0, 0, 0.8);
    padding-top: .5rem;
    padding-bottom: .5rem;
}
nav .navbar-collapse.collapse {
    display: block;
}
/* @media(max-width: 991px)
{
    nav .navbar-collapse.collapse{
        overflow: hidden;
    }
    nav .navbar-collapse.active{
        height: 17vh !important;
    }
} */
nav .navbar-collapse {
    transition: .6s all ease-in-out;
    height:  0 !important;
}
.li_text{

    margin-right: 10px ;
    font-family: 'Heebo';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
text-align: right;
}