.backgroundPage{
    background: #000000;
    
    height: 100%;
    /* min-width: 400px; */
    /* margin: 80px; */
    
}
.title{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 37px;
}


.custom-slider {
    width: 80%;
    height: 30px;
    background-image: #37C25E;
    background: #d3d3d3; 
    opacity: 0.8;
    outline: none;
    transition: 0.3s;
}

.price{

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* text-align: center; */
    
    color: #FFFFFF;
    text-align: right;

}
.title_map{

font-family: 'Heebo';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 37px;
color: #37C25E;

}

.input_price{
    text-align: right;
    border: 1px solid #616161;
border-radius: 6px;

width: 85%;
    min-height: 40Px;
    font-size: 1rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    margin-right: 0.25rem;
    outline: none;

}

.form_setting{
 display: flex;
 flex-direction: column;
 align-items: center;

}
.change_setting{
    width: fit-content;
    height: fit-content;
    /* text-align:center  ; */
    color: white;
}


  
  /* input[type="range"]:focus,
  input[type="number"]:focus {
    box-shadow: 0 0 3px 1px #4b81dd;
    outline: none;
  } */
  
  input[type="range"] {
    --webkit-appearance: none;
    /* margin-right: 15px; */
    /* width: 200px; */
    height: 7px;
    background: #5F5F5F;
    border-radius: 5px;
    background: linear-gradient(359.73deg, rgba(55, 194, 95, 0.11) -42.48%, rgba(55, 194, 95, 0.720652) 30.89%, #37C25F 60.02%);
    /* background-size: contain; */
    background-repeat: no-repeat;
  }
  
  /* Input Thumb */
   input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ff4500;
    /* cursor: ew-resize; */
    box-shadow: 0 0 2px 0 #555;
    /* transition: background .3s ease-in-out; */
  }
  
 
