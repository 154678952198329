.div_col{
    width: 5px;
    height: fit-content;
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;
gap: 6px; ;
margin: 3px
}
.svg_col{
    position: relative;
    height: 100%;
}
.Rectangle1-green{
   
    position: absolute;
width: 5px;
height: 7px;
background: #1AF859;
border-radius: 1px;
}
.Rectangle2-green{
   position: absolute;
    width: 4.64px;
    height: 9px;
    background: #1AF859;
    filter: blur(2px);
}
.col-green{
    /* position: relative;  */
    width: 5px;
    border-radius: 50px;

    
    background: linear-gradient(0.14deg, rgba(55, 194, 95, 0.11) 18.55%, #37C25F 88.05%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25)
}
.Rectangle1-red{
   
    position: absolute;
width: 5px;
height: 7px;
background: #FF612Fed;
border-radius: 1px;
}
.Rectangle2-red{
   position: absolute;
    width: 4.64px;
    height: 9px;
    background: #FF5620;
    filter: blur(2px);
}
.col-red{
    /* position: relative;  */
    width: 5px;
    border-radius: 50px;
    background: linear-gradient(0.14deg, #312A28 18.55%, #C9502A 88.05%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.date{
    bottom: -20px;
    position: absolute;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 17px;
transform: rotate(-47.54deg);
}
.b{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}
