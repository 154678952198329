.claner-warp{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    width: 376px;
    border-radius: 45px;
    background: radial-gradient(76.07% 53.02% at 50.11% 99.65%, #696868 0%, #5B5A5A 20%, #373737 57%, #080808 100%);
    
}
.claner-warp1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 364px;
    background: #0B090A;
    border-radius: 45px;
    
    
}
.claner-warp-inner1{
    background: linear-gradient(0deg, #339F52 100%, rgba(53, 184, 90, 0) 0%);
    width: 358px;
    height: 58px;

    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    



   
    
}
.claner-warp-inner{

    width: 350px;
    height: 50px;
    background: linear-gradient(180deg, #37C25E -6.09%, #20562F 119.11%); ;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}
.claner-warp-inner-disable{

    width: 350px;
    height: 50px;
    background: linear-gradient(180deg, gray -6.09%, #20562F 119.11%); ;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}