.recr_onel{
    position: absolute;
    width: 14px;
    height: 19px;
    left: -14px;
    background: #06FF4E;
    filter: blur(10px);
}
.recr_twol{
    position: absolute;
width: 7.86px;
height: 7.86px;
left: -10px;
background: #1AF859;
}




/* .recr_oner{
    position: absolute;
    width: 14px;
    height: 19px;
    right: 0x;
    background: #06FF4E;
    filter: blur(10px);
}
.recr_twor{
    position: absolute;
width: 7.86px;
height: 7.86px;
right: 0;
background: #1AF859;
} */



.one{
    position: absolute;
    /* top:18% */
    top: 176px;
}
.oner{
    position: absolute;
    /* top:18% */
    top: 176px;
    right: 5px;
}
.two{
    position: absolute;
    /* top: 56% */
    top: 568px;
}
.twor{
    position: absolute;
    /* top: 56% */
    top: 568px;
    right: 5px;
}
.three{
    position: absolute;
    /* top:88% */
    top: 892px;
}
.threer{
    position: absolute;
    /* top:88% */
    top: 892px;
    right: 5px;
}
.one1{
    position: absolute;
    /* top:19% */
    top: 186px;
}
.one1r{
    position: absolute;
    /* top:19% */
    top: 186px;
    right: 5px;
}
.two2{
    position: absolute;
    /* top:57% */
    top: 578px;
}
.three3{
    position: absolute;
    /* top: 89% */
    top: 882px;
}
.two2r{
    position: absolute;
    /* top:57% */
    top: 578px;
    right: 5px;
}
.three3r{
    position: absolute;
    /* top: 89% */
    top: 882px;
    right: 5px;
}