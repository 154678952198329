.MMM1{
    /* width: 50%;
    height: 30%; */
    position: relative;
    top: 19px;
    /* width: 113px;
    height: 53px;
    left: 141px;
    top: 21px; */
    
}
.logo-login{
    position: absolute;
    height: 100px;
    /* width: 100px; */
}
.dic{
    display: flex;
    justify-content: center;
}
.logo-nav{
    padding-top: 15px;
    height: 100px;
}