.charts{
    display: flex;
flex-direction: row;
align-items: flex-end;
justify-content: space-between;
padding: 15px;
gap: 1px;

/* position: relative; */
width: 380px;
height: 235px;
}
.load_charts{
    display: flex;
justify-content:center;
align-items: center;
width: 380px;
height: 235px;
}

.load_prime{
    position: absolute;
    justify-content: center;
    width: 100%;
    top: 43%;

}

.chart-row{  display: flex;
    flex-direction: column;
    align-items: flex-end;}

.chart_unit{
    font-family: 'Montserrat';
font-style: normal;
/* font-weight: 400; */
font-size: 10px;
/* line-height: 58px; */
/* or 580% */


color: #FFFFFF;
}