@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.btnBoxShadon{
  background: rgba(174, 236, 102, 0.952);
  box-shadow:  0px 0px 1px 2px rgb(19, 145, 218);
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 10px;
  border: 3px solid rgba( 237, 229, 229, 0 );
}
.navSiteInfo{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.glassmorphismCircleIcon
{
  background: rgba(240, 11, 11, 0);
  box-shadow: -4px 4px 3px 1px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 30px;
  border: 2px solid rgba(10, 10, 10, 0.541);
  padding: 4px;
}

.glassmorphism
{
  background: rgba( 237, 229, 229, 0 );
  box-shadow: 5px 5px 20px 4px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 10px;
  border: 3px solid rgba( 237, 229, 229, 0 );
  padding: 0.5px;
}
.greenGlassmorphism
{
  background: rgba(15, 226, 103, 0.945);
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 10px;
  border:3px solid rgb(20, 20, 20);
  filter: drop-shadow(4px 4px 1px rgba(240, 234, 234, 0.5));
}


.circleGlassmorphism
{
  
  border:1px solid rgb(20, 20, 20);
  background: rgba(10, 10, 10, 0);
  border-radius: 16px;
  box-shadow:  0px 4px 0px 2px rgba(54, 54, 54, 0.945),
               0px -4px 0px 2px rgba(70, 68, 68, 0.945), 
              10px 0px 10px 0px rgba(31, 105, 62, 0.945),
              -10px 0px 10px 0px rgba(31, 105, 62, 0.945) 
              ;

}

html {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: rgb(231, 47, 47);
}


body {
  height: 100vh;
  height: 100%;
  background-color: rgb(236, 29, 29);
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  /* flex-direction: column; */
}

#root {
  zoom: 0.8;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  background-color: rgb(238, 236, 236);
}

.App {
  max-width: 800px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(238, 236, 236);
  /* border: 0.5px solid rgb(48, 3, 3);
  box-shadow: 0px 0px 15px gray; */
}

.inputBostrap,.image_login{
  width: 100%; 
  max-width: 700px; 
 

}
.image_login{
  align-self: center;
  
}

.button_login,.button_register,.button_register_new{

  width: 100%;
  margin-top: 2rem;
  padding: 0px;
  border-radius: 50px;
  background: none;
  border:0;
  max-width: 700px;
  
}

.button_register{
  margin-top: 0rem;
  display: block;
  align-self: center;
    
}
.div_email{
  display: flex;
  flex-direction: row;
  justify-content:  center;
  margin-top: 1rem; 
}

section {
    width: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(100, 93, 93, 0.4);
}

form {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

a, a:visited {
    color: #fff;
}

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  text-align: right;
  font-size: 22px;
  padding: 0.25rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  background: none;
  border: 1px solid rgba(97, 97, 97, 1);
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
}

.instructions {
    font-size: 0.85rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}
.image_login{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.Header, .Footer {
  width: 100%;
  background-color: #66d8f5;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header h1 {
  font-size: 1.5rem;
}

.Header svg {
  font-size: 2rem;
}

.Footer {
  padding: 0.75rem;
  display: grid;
  place-content: center;
}

.Nav {
  width: 100%;
  height: 60px;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.searchForm {
  width: 80%;
  padding: 1rem 0 0 0.75rem;
}

.searchForm input[type="text"] {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  min-height: 48px;
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  outline: none;
}

.searchForm label {
  position: absolute;
  left: -99999px;
}

.Nav ul {
  color: #fff;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.Nav li {
  padding: 1rem;
}

.Nav li:hover, 
.Nav li:focus {
  padding: 1rem;
}

.Nav li a {
  color: #fff;
  text-decoration: none;
}

.Nav li:hover, 
.Nav li:focus, 
.Nav li:hover a, 
.Nav li:focus a  {
  background-color: #eee;
  color: #333;
}

.Nav li:hover a, 
.Nav li:focus a {
  cursor: pointer;
}

.Home, .NewPost, .PostPage, .About, .Missing {
  width: 100%;
  flex-grow: 1;
  padding: 0.5rem;
  overflow-y: auto;
  background-color: #fff;
}


.site {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  border-bottom: 1.25px solid rgb(26, 25, 25);
  color: rgba(0, 0, 0, 0.952);
}

.Home .site a {
  text-decoration: none;
}

.Home .site a, 
.Home .site a:visited {
  color: #000;
}

.site:first-child {
  margin-top: 0;
}

.site:last-child {
  border-bottom: none;
}

.siteDate {
  font-size: 0.75rem;
  margin-top: 0.1rem;
  color: #000;
}

.siteBody {
  margin: 0.3rem 0;
  color: #000;
}

.addSiteBelong {
  display: flex;
  flex-direction: column;
  color: #000;
  justify-content: center;
  align-items: center;
}

.addSiteBelong label {
  margin-top: 1rem;
}

.addSiteBelong input[type='text'], input[type = 'number'] ,
.addSiteBelong textarea {
  font-family: 'Open Sans', sans-serif;
  width: 60%;
  min-height: 20px;
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-right: 0.25rem;
  outline: none;
}

.addSiteBelong textarea {
  height: 100px;
}

.Home .addSiteBelong button {
  margin-top: 1rem;
  height: 30px;
  width: 36%;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 0.75rem;
  cursor: pointer;
  background-color: rgba(255, 153, 0, 0.877);
  color: rgba(0, 0, 0, 0.952);
  font-family: 'Open Sans', sans-serif;
}

.Missing h2, .PostPage h2, 
.Missing p, .PostPage p {
  margin-bottom: 1rem;
}

.Home button {
  /* height: 36px;
  width: 70%;
  border-radius: 10px;
  font-size: 0.7rem;
  background-color: rgba(255, 153, 0, 0.877);
  color: rgba(0, 0, 0, 0.952);
  cursor: pointer;
  margin-left: 0.05rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; */
  
  border-radius: 2px;
  font-size: 0.7rem;
  background-color: rgba(255, 153, 0, 0.877);
  color: rgba(0, 0, 0, 0.952);
  cursor: pointer;
  margin-left: 0.05rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.statusMsg {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  color: rgb(44, 1, 1);
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  border-bottom: 1px solid rgb(26, 25, 25);
  padding: 0.5ch;
}

@media only screen and (min-width: 610px) {
  html {
    font-size: 16px;
  }

  .Header h1 {
    font-size: 2rem;
  }

  .Nav {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .Nav ul {
    text-align: right;
  }

  .Nav li:hover, 
  .Nav li:focus, 
  .Nav li:hover a, 
  .Nav li:focus a  {
    background-color: #eee;
    color: #333;
  }

  .searchForm {
    width: 50%;
    padding: .5rem 0;
  }
 
  .searchForm input[type="text"] {
    margin-left: 0.5rem;
  }

  .newPostForm textarea {
    height: 300px;
  }
}

@media only screen and (min-width: 992px) {

  .Header svg {
    font-size: 3rem;
  }

}



/* Popup style */
.popup-box {
  position: static;
  width: 100%;
  height: 50vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1.25px solid #999;
  overflow: auto;
  color: #000;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(18% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.SITEINFO{
  padding: 5px;
  height: 25px;
  min-width: 25px;
  border-radius: 6px;
  font-size: 0.78rem;
  background-color: rgba(250, 193, 139, 0.877);
  color: rgba(0, 0, 0, 0.952);
  cursor: pointer;
  margin-bottom: 0.6rem;
  font-family: 'Open Sans', sans-serif;
}
.border-1 {
  border-width:10px !important;
}

.under1{

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}