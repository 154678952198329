.Button_moth_warop{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 80.26885986328125px;
    width: 177.42161560058594px;
    background: linear-gradient(92.96deg, #353535 2.81%, #161616 96.86%);
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.7);
    border-radius: 28px ;

}
.Button_moth_warop_inner{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    background: linear-gradient(183.18deg, #161616 2.82%, #353535 96.81%);
    height: 69.58171844482422px;
    width: 164.88856506347656px;
    border-radius: 28px;
}
.operator{
    font-family: 'Montserrat Alternates';
font-style: normal;
font-weight: 500;
font-size: 34.1796px;
line-height: 42px;
/* identical to box height */


color: #FF612F;


}
.operator1{
    font-family: 'Montserrat Alternates';
font-style: normal;
font-weight: 500;
font-size: 34.1796px;
line-height: 42px;
/* identical to box height */


color: #33AD55


}
.lose_year{

font-family: 'Heebo';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* or 150% */
text-align: right;
color: #E1E1E1;
    
}
.amount{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-shadow: #FF612F -1px 1px 2px, #FF612F -1px 2px 20px;
    color: #FF612F;
    
}
.amount1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-shadow: #33AD55 -1px 1px 2px, #33AD55 -1px 2px 20px;
    color: #33AD55;
}

