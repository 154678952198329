.text-header{
width: 163px;
height: 37px;
left: 133px;
top: 86px;

font-family: 'Heebo';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 37px;
/* identical to box height */


color: #FFFFFF;

}
.allpage{
    display: flex;
    justify-content: center;
    /* flex: cen;; */
    width: 100%;
    background: linear-gradient(192.12deg, #98A0A4 -24.36%, #2A2B2C 11.38%, #19191A 47.12%, #131313 82.85%, #606060 118.59%);
}