/* .barbar{
    position: absolute;
    width: 392px;
    height: 78.36px;
    left: 15px;
    top: 123px;
} */
.barbar{
    /* position: relative; */
    /* left: 35%; */
    /* width: 100%; */
    /* height: 78.36px; */
    padding: 0%;
    margin: 0%;}
    
    
.all_group_radios{
    
    position: absolute;
    display: flex;
    margin: 3px;
    /* justify-content: space-between;
    left: 0%; */
    /* justify-content: space-between; */
    width: 330px;
    /* left: 160px; */
    flex-direction: row;

border: 0;
background:none;
}

.matan{
    /* position: relative; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* justify-items: center; */
    /* background:red; */
    height: fit-content;

}
.daily{
    margin-right: 2px;
    padding: 5px;
    padding-right: 5px;
    width: 10px;
    height: 24px;
    margin-right: 2.5em; ;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    border: 0;
    color: grey;
    --bs-btn-active-bg:none;
}


.monthly{
     
    padding: 3px;
    width: 10px;
    height: 24px;
    margin-right: 2.5em; ;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: grey;
    --bs-btn-active-bg:none;
border: 0;
background:none;

}
.yearly{

    padding: 3px;
    width: 3px;
    height: 24px;
    margin-left: 1.4em; ;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: grey;
    --bs-btn-active-bg:none;
    /* 

font-family: 'Heebo';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 22px;
text-align: center; */
    border: 0;
background:none;
}
