.profile{
    /* display: flex; */
    position: absolute;
}
.nev_menu{
    width: max-content;
    display: flex;
    justify-content: end;
    /* position: fixed;  */
    background: linear-gradient(207.62deg, #121010 19.27%, #3C3C3C 97.53%);
    right: 10px;
    z-index:5;
    position: absolute;
    transition: 850ms; 
    border-radius: 30px;
}
.nev-menu1{
    
    display: none;
    }
/* .nav-bar{ */
    
    /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; */
/* } */
.nav_item{
    height: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 8px 8px 8px 16px;
}
.nav-menu-items{
    width: fit-content;
    padding: 0px;
    margin: 0px;
    

}