/* .sd{
    position: absolute;
    width: 388px;
    height: 388px; 
    left: -8px;
    top: 540px;
    padding: 0;
    margin: 0;
} */
.mix_multiply {
    mix-blend-mode: multiply;
}
.mix_screen{
    mix-blend-mode: screen;
}
.sad{
    position: absolute;
    padding-top: 10px;
    padding-right: 10px;
    /* left: 124px;
    top: 680.728515625px; */

}
.exploit1{
    /* mix-blend-mode: screen;
    color: red;
    left: 150px; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;

    color: #E1E1E1;
}
.all{
    /* width: 310px; */
    height: 350px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}